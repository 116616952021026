<template>
    <el-dialog
        title="Business Structure"
        width="30%"
        :visible="true"
        :show-close="false"
        @close="$emit('close')"
    >
        <el-form ref="form">
            <el-form-item label="Business Structure Name">
                <el-input
                    v-if="updatingParentName"
                    v-model="businessStructureName"
                />
                <el-select
                    v-else
                    v-model="newBusinessStructureId"
                    placeholder="Select"
                    @change="changeParent"
                >
                    <el-option-group
                        v-for="(group, index) in options"
                        :key="index"
                    >
                        <el-option
                            v-for="item in group.options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-option-group>
                </el-select>
            </el-form-item>
        </el-form>
        <span
            slot="footer"
            class="dialog-footer"
        >
            <el-button @click="closeModal">Cancel</el-button>
            <el-button
                :loading="loading"
                type="success"
                @click="saveParent"
            >Save</el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
    name: "BaseParentEntityForm",

    props: {
        availableBusinessStructures: {type: Array, default: () => []},
        businessStructure: {type: Object, default: () => ({})},
        loading: {type: Boolean, required: true},
    },

    data() {
        return {
            updatingParentName: false,
            businessStructureName: null,
            newBusinessStructureId: null,
        }
    },

    created() {
        if (!this.availableBusinessStructureOptionsExceptThisOne.length) {
            this.updatingParentName = true;
        }
        this.businessStructureName = this.businessStructure.name;
    },

    computed: {
        availableBusinessStructureOptions() {
            return this.availableBusinessStructures
                .map(businessStructure => ({
                    id: businessStructure.id,
                    name: businessStructure.name
                }));
        },
        availableBusinessStructureOptionsExceptThisOne() {
            return this.availableBusinessStructureOptions
                .filter(businessStructureOption => businessStructureOption.id !== this.businessStructure.id);
        },
        options() {
            return [{
                options: this.availableBusinessStructureOptionsExceptThisOne
            }, {
                options: [{
                    id: "updateBusinessStructureName",
                    name: this.updateNameLabel
                },]
            }];
        },

        updateNameLabel() {
            return (this.businessStructure.name ? "Edit " : "Create ") + "Business Structure Name";
        }
    },

    methods: {
        changeParent(selection) {
            if (selection === "updateBusinessStructureName") {
                this.updatingParentName = true;
            }
        },

        closeModal() {
            this.$emit("close")
        },

        saveParent() {
            if (this.updatingParentName) {
                this.$emit("update", this.businessStructureName);
            } else {
                this.$emit("merge", this.newBusinessStructureId);
            }
        },
    },





}
</script>

<style scoped>


</style>