<template>
    <BasePanel
        v-loading="loading"
        :class-name="panelClasses"
        element-loading-background="transparent"
    >
        <BaseDisplayTree
            v-if="isTreeVisible"
            :business-structure="businessStructure"
            :entity-purposes="entityPurposes"
            :allow-edit="!businessStructure.is_complete"
            :validation-messages="validationMessages"
            @set-is-complete="setIsComplete"
            @entity-command="handleEntityCommand"
            @parent-command="handleParentCommand"
        />
        <BaseStartPage
            v-else-if="!loading"
            @click="showTreeAndOpenEntityForm"
        />
        <BaseEntityForm
            v-if="modalDialogVisible"
            v-model="selectedEntity"
            :farms="availableFarms"
            :entity-types="entityTypes"
            :entity-purposes="entityPurposes"
            :validation-messages="validationMessages"
            :are-farms-loading="areFarmsLoading"
            :submit-loading="loading"
            @query-farms="loadAvailableFarms"
            @changed-entity="clearFarms"
            @save-entity="saveEntity"
            @close="onEntityFormClose"
        />
        <BaseParentEntityForm
            v-if="showEditParentModal"
            :available-business-structures="availableBusinessStructures"
            :business-structure="clonedBusinessStructure"
            :loading="loading"
            @update="updateBusinessStructureAndClose"
            @merge="mergeBusinessStructureAndClose"
            @close="showEditParentModal = false"
        />
    </BasePanel>
</template>

<script>
import BasePanel from "BusinessStructure/Assets/Components/BasePanel";
import BaseDisplayTree from "BusinessStructure/Assets/Components/BaseDisplayTree";
import BaseEntityForm from "BusinessStructure/Assets/Components/BaseEntityForm";
import vuexModule from "Figured/Assets/Modules/vuex/module-helper";
import BaseStartPage from "BusinessStructure/Assets/Components/BaseStartPage";
import VuexBusinessStructureModule from "../Store/business-structure";
import BaseParentEntityForm from "BusinessStructure/Assets/Components/BaseParentEntityForm";

const {
    RegisterVuexModuleMixin,
    mapState,
    mapActions,
} = vuexModule("business-structure", VuexBusinessStructureModule);

import {
    STATE,
    ACTIONS
} from "../Store/constants";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";

export default {
    name: "DisplayTree",

    mixins: [
        RegisterVuexModuleMixin,
    ],

    components: {
        BaseEntityForm,
        BaseStartPage,
        BaseDisplayTree,
        BasePanel,
        BaseParentEntityForm,
    },

    data() {
        return {
            modalDialogVisible: false,
            selectedEntity: {},
            isTreeVisible: false,
            showEditParentModal: false,
            clonedBusinessStructure: {}
        }
    },

    async created() {
        await this.loadBusinessStructure();

        this.businessStructure.id && this.showTree();
    },

    computed: {
        ...mapState({
            loading: STATE.LOADING,
            businessStructure: STATE.BUSINESS_STRUCTURE,
            entityTypes: STATE.ENTITY_TYPES,
            entityPurposes: STATE.ENTITY_PURPOSES,
            errorMessage: STATE.ERROR_MESSAGE,
            validationMessages: STATE.VALIDATION_MESSAGES,
            availableFarms: STATE.AVAILABLE_FARMS,
            areFarmsLoading: STATE.AVAILABLE_FARMS_LOADING,
            availableBusinessStructures: STATE.AVAILABLE_BUSINESS_STRUCTURES
        }),

        deleteEntityConfirmMessage() {
            return this.validationMessages.notAllowed;
        },

        panelClasses() {
            const classes = {
                "business-structure": true
            };

            if (this.isTreeVisible && !this.businessStructure.is_complete) {
                classes["business-structure--incomplete"] = true;
            }

            return classes;
        },
        entities() {
            return filter(this.businessStructure.entities, entity => entity.farm);
        },
        dropDownEntityCommands() {
            return {
                edit: entity => {
                    this.selectEntity(entity);
                    this.openEntityForm();
                },
                create: () => {
                    this.selectEntity({});
                    this.openEntityForm();
                },
                delete: (entity) => {
                    this.deleteEntityConfirm(entity);
                }
            }
        },
        dropDownParentCommands() {
            return {
                edit: async () => {
                    if (!this.businessStructure.id) {
                        this.$notify.warning("Please save at least one entity in your business structure before adding a parent business");
                        return;
                    }

                    await this.loadAvailableBusinessStructures();

                    this.clonedBusinessStructure = cloneDeep(this.businessStructure);
                    this.showEditParentModal = true;
                },
                delete: () => {
                    //* here we are simply settling the business structure name to null
                    this.updateBusinessStructure(null);
                }
            }
        },
    },

    methods: {
        ...mapActions({
            setIsComplete: ACTIONS.SET_IS_COMPLETE,
            deleteEntity: ACTIONS.DELETE_ENTITY,
            storeEntity: ACTIONS.STORE_ENTITY,
            loadBusinessStructure: ACTIONS.LOAD_BUSINESS_STRUCTURE,
            clearValidationMessages: ACTIONS.CLEAR_VALIDATION_MESSAGES,
            loadAvailableFarms: ACTIONS.LOAD_AVAILABLE_FARMS,
            clearFarms: ACTIONS.CLEAR_FARMS,
            loadAvailableBusinessStructures: ACTIONS.LOAD_AVAILABLE_BUSINESS_STRUCTURES,
            mergeBusinessStructureInto: ACTIONS.MERGE_BUSINESS_STRUCTURE,
            updateBusinessStructure: ACTIONS.UPDATE_BUSINESS_STRUCTURE,
        }),
        async mergeBusinessStructureAndClose(newBusinessStructureId) {
            await this.mergeBusinessStructureInto(newBusinessStructureId);
            this.showEditParentModal = false;
        },
        async updateBusinessStructureAndClose(newName) {
            await this.updateBusinessStructure(newName);
            this.showEditParentModal = false;
        },
        onEntityFormClose() {
            this.modalDialogVisible = false;

            // Go back to the Get Started page if the user cancels without saving the first entity
            if (!this.businessStructure.id) {
                this.showTree(false);
            }
        },
        handleEntityCommand({entity, command}) {
            this.dropDownEntityCommands[command](entity);
        },
        handleParentCommand({command}) {
            this.dropDownParentCommands[command]();
        },
        selectEntity(entity) {
            this.selectedEntity = entity;
            this.clearValidationMessages();
        },
        async saveEntity(entity) {
            if (await this.storeEntity(entity)) {
                this.modalDialogVisible = false
            }
        },
        showTree(visible = true) {
            this.isTreeVisible = visible;
        },
        showTreeAndOpenEntityForm() {
            this.showTree();
            this.selectEntity(this.businessStructure.entities[0]);
            this.openEntityForm();
        },
        openEntityForm() {
            this.modalDialogVisible = true;
        },
        async deleteEntityConfirm(entity){
            const success = await this.deleteEntity({entityId: entity.id});

            if (success || !this.deleteEntityConfirmMessage) {
                return;
            }

            try {
                await this.$confirm(this.deleteEntityConfirmMessage, "Delete Entity from Business Structure", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    confirmButtonClass: "btn-danger-red-bg",
                    showClose: false,
                });

                await this.deleteEntity({entityId: entity.id, confirm: true});

                this.showTree(false);
            } catch (err) {
                //do nothing, user cancelled
            }
        },
    },

    watch: {
        errorMessage(newErrorMessage) {
            newErrorMessage && this.$notify.error(newErrorMessage);
        }
    },
}
</script>

<style lang="scss">
.business-structure--incomplete {
    border: 1px solid #7FA9EA;
    background-color: #ECF2FC;
}
.business-structure {
    h4 {
        margin-bottom: 25px;
    }

    .el-col {
        margin-top: 8px;
    }

    .el-divider {
        background-color: #90A3AE;
        margin: 20px 0 12px;
    }
    min-height: 338px;
}
</style>