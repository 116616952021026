<template>
    <div :class="classes">
        <div class="panel-body">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "BasePanel",
    props: {
        className: {
            type: [String, Object],
            required: false,
            default: ""
        }
    },
    computed: {
        classes() {
            const classes = {
                "panel": true,
                "panel-default": true,
                "panel-page": true,
            };

            if (typeof this.className === "string") {
                this.className.split(" ").forEach(className => {
                    classes[className] = true;
                })
            } else {
                Object.assign(classes, this.className);
            }

            return classes;
        }
    }
}
</script>

<style scoped>
.panel {
    display: flex;
    flex-direction: column;
}
.panel-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
</style>